import React, { Suspense } from 'react';
import { createRoot, hydrateRoot, Root } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import widgetRoute from './widget';
import adminRoute from './admin';
import clientRoutes from './client';
import ErrorPage from './shared/components/ErrorPage';
import PathParam from './shared/PathParam';

const LandingApp = React.lazy(() => import('./landing/LandingApp'));

const routes = [
  widgetRoute,
  adminRoute,
  ...clientRoutes,
  {
    path: '/',
    element: <LandingApp />,
    errorElement: <ErrorPage />,
  },
  {
    path: `/landing/:${PathParam.Variant}`,
    element: <LandingApp />,
    errorElement: <ErrorPage />,
  },
];

function LoadingSection() {
  return (
    <div className="container text-center">
      <div className="spinner-border text-danger m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function Index() {
  return (
    // <React.StrictMode>
    <main>
      <Suspense fallback={<LoadingSection />}>
        <RouterProvider router={router} />
      </Suspense>
    </main>
    // </React.StrictMode>
  );
}

const router = createBrowserRouter(routes);

const rootDiv = document.getElementById('root') as HTMLElement;

let root: Root;
if (rootDiv.hasChildNodes()) {
  root = hydrateRoot(rootDiv, <Index />);
} else {
  root = createRoot(rootDiv);
  root.render(<Index />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
