import { useRouteError } from 'react-router-dom';
import './ErrorPage.scss';

export function ErrorBoundary() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <div className="error-page container pt-5">
      <div className="alert alert-danger" role="alert">
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
    </div>
  );
}

export default ErrorBoundary;
