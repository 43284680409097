import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes as dashboardRoutes } from './Routes';
import ErrorPage from '../shared/components/ErrorPage';

import '../css/control.scss';

const ClientApp = React.lazy(() => import('./ClientApp'));

const routes = [
  {
    path: '/dashboard',
    element: <ClientApp />,
    errorElement: <ErrorPage />,
    children: dashboardRoutes,
  },
  {
    path: '/client',
    element: <Navigate to="/dashboard" />,
  },
];

export default routes;
