import React from 'react';

const WidgetApp = React.lazy(() => import('./WidgetApp'));

const route = {
  path: '/widget/:widgetToken',
  element: <WidgetApp />,
};

export default route;
