import React from 'react';
import './NotFoundPage.scss';

function NotFoundPage() {
  return (
    <div className="notfound-page">
      <div className="notfound-con">
        <header>404</header>
        <p className="notfound-text">Page not found</p>
      </div>
    </div>
  );
}

export default NotFoundPage;
