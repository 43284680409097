import React from 'react';
import { Navigate } from 'react-router-dom';
import ErrorPage from '../shared/components/ErrorPage';

// const ClientApp = React.lazy(() => import('./ClientApp'));
const AboutPage = React.lazy(() => import('./AboutPage'));
const BattlePage = React.lazy(() => import('./BattlePage'));
const ChatPage = React.lazy(() => import('./ChatPage'));
const DebugPage = React.lazy(() => import('./DebugPage'));
const HomePage = React.lazy(() => import('./HomePage'));
const LogOutPage = React.lazy(() => import('./LogOutPage'));
const PlayersPage = React.lazy(() => import('./PlayersPage'));
const SettingsPage = React.lazy(() => import('./SettingsPage'));
const SoundPage = React.lazy(() => import('./SoundPage'));
const WidgetPage = React.lazy(() => import('./WidgetPage'));
const LogInPage = React.lazy(() => import('./LogInPage'));
const NotFoundPage = React.lazy(
  () => import('../shared/components/NotFoundPage'),
);

export const routes = [
  {
    index: true,
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'home',
    element: <Navigate to="/dashboard" />,
  },
  {
    path: 'players',
    element: <PlayersPage />,
  },
  {
    path: 'battles',
    element: <BattlePage showSettings />,
  },
  {
    path: 'rewards',
    element: <SettingsPage />,
  },
  {
    path: 'sounds',
    element: <SoundPage />,
  },
  {
    path: 'chat',
    element: <ChatPage />,
  },
  {
    path: 'widget',
    element: <WidgetPage />,
  },
  {
    path: 'debug',
    element: <DebugPage />,
  },
  {
    path: 'about',
    element: <AboutPage />,
  },
  {
    path: 'logout',
    element: <LogOutPage />,
  },
  {
    path: 'login',
    element: <LogInPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
