import React from 'react';
import { routes as adminRoutes } from './ARoutes';
import ErrorPage from '../shared/components/ErrorPage';

import '../css/admin.scss';

const AdminApp = React.lazy(() => import('./AdminApp'));

const route = {
  path: '/admin',
  element: <AdminApp />,
  errorElement: <ErrorPage />,
  children: adminRoutes,
};

export default route;
